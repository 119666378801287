import React from "react";
import { Container, Row, Col } from "react-grid-system";
// Components
import ProgressBar from "../../components/common/ProgressBar";

const skills = [
    {
        title: "Object Oriented Programming",
        percent: 85,
    },
    {
        title: "Java",
        percent: 85,
    },
    {
        title: "C#",
        percent: 40,
    },
    {
        title: "Kotlin - Android App Development",
        percent: 75,
    },
    {
        title: "Swift - IOS App Development",
        percent: 75,
    },
    {
        title: "ReactJS, Angular - Web Development",
        percent: 40,
    },
    
];
const education = [
    {
        title: "University of Rochester",
        subtitle: "Rochester, NY",
        description: "Couesework: Data Structures and Algorithms, Introduction to Artificial Intelligence, AR/VR Design, Mobile App Development, Web Programming, Introduction to Statistics, etc.",
        date: "(2016-2018)",
        icon: "ti-crown",
    },
    // {
    //     title: "Royal Holloway",
    //     subtitle: "Bachelor Degree | London",
    //     description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
    //     date: "(2016-2018)",
    //     icon: "ti-cup",
    // },
    // {
    //     title: "Stanford University",
    //     subtitle: "Web Design Course | New York",
    //     description: "Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo.",
    //     date: "(2016-2018)",
    //     icon: "ti-ruler-alt-2",
    // },
];
const experience = [
    {
        title: "Technology Intern",
        subtitle: "M&T Bank | Buffalo, NY",
        description: "Develop a C# application to verify the environment setup for work PCs on 440 bank branches ",
        description2: "Wireframe and develop an Angular front-end web to display internal transactional information for evaluation of the health and performance of the chosen branch",
        date: "(Jun. 2021 - Present)",
        icon: "ti-pencil-alt",
    },
    {
        title: "Teaching Assistant for Intro to Artificial Intelligence",
        subtitle: "University of Rochester | Rochester, NY",
        description: "Facility weekly study session to help around 5 students understand class topics such as Minimax algorithms, state-space search, constraint satisfaction, proposition logic, etc.",
        description2: "Provide feedback and grading to a total of 40 student projects throughout the course duration",
        date: "(Aug. 2021 - Present)",
        icon: "ti-heart",
    },
    {
        title: "Research Assistant",
        subtitle: "Rochester Human-Computer Interaction | Rochester, NY",
        description: "Read published paper on common sense reasoning and participated in weekly team meetings",
        description2: "Tested the performance of GPT-2 based knowledge-aware commonsense reasoning model",
        date: "(Feb. 2021 - May 2021)",
        icon: "ti-paint-bucket",
    },
];

function Resume() {
    return (
        <section className="section section-resume section-resume-1">
            <div className="display-spacing">
                <header className="el-heading el-heading-center">
                    <div className="el-icon">
                        <h2 className="el-icon-title">
                            <span>RESUME</span>
                        </h2>
                    </div>
                    <h3>Education & Experience</h3>
                    <div className="divider divider-1-reverse divider-1-1"></div>
                    <div className="divider divider-1-reverse divider-1-2"></div>
                </header>
                <Container className="mb-spacing">
                    <Row>
                        <Col md={2}> </Col>
                        <Col md={8}>
                            <div className="resume-row">
                                <h4 className="resume-title">Education</h4>
                                <ul>
                                    {education.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>{item.subtitle}</h6>
                                                    {/* <p></p> */}
                                                </div>
                                                <div className="resume-body">
                                                    
                                                    <p>{item.description}</p>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/* </Col>
                            <Col md={6}> */}
                            <div className="resume-row">
                                <h4 className="resume-title">Experience</h4>
                                <ul>
                                    {experience.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>
                                                        {item.subtitle} <small>{item.date}</small>
                                                    </h6>
                                                </div>
                                                <div className="resume-body">
                                                    {/* <li>
                                                        <ul/>{item.description}
                                                        <ul/>{item.description2}
                                                    </li> */}
                                                    <p>▪ {item.description}</p>
                                                    <p>▪ {item.description2}</p>

                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col md={2}> </Col>

                    </Row>
                </Container>
                <Container>
                    <Row>
                        {skills.map((item, index) => (
                            <Col key={index} sm={6} xl={4}>
                                <div className="skills-item">
                                    <ProgressBar label={item.title} percent={item.percent} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Resume;
