import React from "react";
import TextLoop from "react-text-loop";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";

function Intro() {
    return (
        <section className="section section-hero section-hero-3 overlay-image" style={{ backgroundImage: `url(/assets/images/hero/intro-dark.jpg)` }}>
            <div className="display-center">
                <Container>
                    <div className="el-heading">
                        <p className="el-icon">
                            <span className="el-icon-title">Hi :) I am Minghui "Emily" Zheng</span>
                        </p>
                        <h1>
                            I love learning about{"\n "}
                            <TextLoop>
                                <span>Software Engineering</span>
                                <span>Web Development</span>
                                <span>App Development</span>
                                <span>Photography</span>
                            </TextLoop>
                        </h1>
                        <Link to="/project">
                            <button className="button button-lg button-primary">
                                <span className="wave"></span>
                                <span className="text">My Projects</span>
                            </button>
                        </Link>
                        <Link to="/about">
                            <button className="button button-lg button-light">
                                <span className="text text-primary">Read More</span>
                            </button>
                        </Link>

                    </div>
                </Container>
            </div>
        </section>
    );
}

export default Intro;
