import React, { useEffect } from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";

// Sections
import Extracurricular from "./Extracurricular";
import About from "./About";
import Intro from "./Intro";
import Header from "./Header";
import Resume from "./Resume";
import Service from "./Service";
import Contact from "./Contact";
import Portfolio from "./Portfolio";
import Project from "./Project"
import Testimonial from "./Testimonial";
import BlogSinglePost from "./BlogSinglePost";
// Components
import Helmet from "../../components/common/Helmet";
import Switch from "../../components/common/Switch";
import RedirectAs404 from "../../components/common/RedirectAs404";

const routes = [
    {
        path: "/",
        component: <Intro />,
    },
    {
        path: "about",
        component: <About />,
    },
    {
        path: "resume",
        component: <Resume />,
    },
    {
        path: "service",
        component: <Service />,
    },
    {
        path: "project",
        component: <Project />,
    },
    {
        path: "portfolio",
        component: <Portfolio />,
    },
    {
        path: "extracurricular",
        component: <Extracurricular />,
    },
    {
        path: "blog/single-post",
        component: <BlogSinglePost />,
    },
    {
        path: "testimonial",
        component: <Testimonial />,
    },
    {
        path: "contact",
        component: <Contact />,
    },
];

function Home() {
    let { path } = useRouteMatch();

    useEffect(() => {
        document.documentElement.className = "home-3 skin-3";
        return () => {
            document.documentElement.className = "";
        };
    });
    return (
        <div>
            <Helmet title="Minghui Zheng" /> 
            <Header />
            <Switch>
                {routes.map((item, index) => (
                    <Route key={index} path={`${path}${item.path}`} exact>
                        {item.component}
                    </Route>
                ))}
                <Route component={RedirectAs404} />
            </Switch>
        </div>
    )
}

export default Home;
